import { db, vertexAI } from '@/config/firebase'
import { getUserData } from './user.service'
import {
  collection,
  collectionGroup,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where
} from 'firebase/firestore'
import type { Application } from '@/models/application.model'
import { ApplicationStatus } from '@/enums/application-status.enum'
import moment from 'moment'
import { QuestionType } from '@/enums/question-type.enum'
import collect from 'collect.js'
import { getUTCDateMoment } from '@/utils/date'
import { v4 as uuidv4 } from 'uuid'
import ApplicationPrompt from '@/prompts/application.prompt'
import { getGenerativeModel } from 'firebase/vertexai-preview'

export const getUserCoursesOnActiveOrganization = async () => {
  const user = await getUserData()
  const peopleQuery = query(
    collectionGroup(db, 'people'),
    where('user_id', '==', user.id),
    where('organization_id', '==', localStorage.getItem('activeOrganization')),
    where('level', '==', '1')
  )
  const peopleData = (await getDocs(peopleQuery)).docs.map((doc) => doc.data())
  const coursesId = pluckPeopleDataBy(peopleData, 'course_id')
  const schoolsId = pluckPeopleDataBy(peopleData, 'school_id')
  return {
    coursesId,
    schoolsId
  }
}

const pluckPeopleDataBy = (peopleData: any, field: string) => {
  return collect(peopleData)
    .pluck(field)
    .unique()
    .filter((item: any) => item)
    .all()
}

export const getUserApplications = async (): Promise<Application[]> => {
  const user = await getUserData()

  const { coursesId, schoolsId } = await getUserCoursesOnActiveOrganization()

  const organizationApplicationRef = collection(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string,
    'applications'
  )

  const organizationApplicationQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('is_apply_to_organization', '==', true)
  )

  const schoolsApplicationsQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('schools_id', 'array-contains-any', schoolsId),
    where('is_apply_to_organization', '==', false)
  )

  const coursesApplicationsQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('courses_id', 'array-contains-any', coursesId),
    where('is_apply_to_organization', '==', false)
  )

  const usersApplicationQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('users_id', 'array-contains-any', [user.id]),
    where('is_apply_to_organization', '==', false)
  )

  const userApplicationsQuery = query(
    collection(user.ref, 'applications'),
    where('organization_id', '==', localStorage.getItem('activeOrganization') as string)
  )

  const userApplicationsData = (await getDocs(userApplicationsQuery)).docs.map((doc) => doc.data())

  return collect([
    ...(await getDocs(organizationApplicationQuery)).docs.map((doc) => doc.data()),
    ...(await getDocs(schoolsApplicationsQuery)).docs.map((doc) => doc.data()),
    ...(await getDocs(coursesApplicationsQuery)).docs.map((doc) => doc.data()),
    ...(await getDocs(usersApplicationQuery)).docs.map((doc) => doc.data())
  ])
    .unique('id')
    .map((application: any) => {
      const userApplication = collect(userApplicationsData)
        .where('application_id', application.id)
        .first()

      if (
        userApplication &&
        ![
          ApplicationStatus.NOT_STARTED.toString(),
          ApplicationStatus.IN_PROGRESS.toString()
        ].includes(userApplication.status)
      ) {
        return null
      } else if (userApplication) {
        return {
          ...userApplication,
          status: ApplicationStatus.IN_PROGRESS.toString()
        }
      }

      return {
        ...application,
        status: ApplicationStatus.NOT_STARTED.toString()
      }
    })
    .filter((application) => application !== null)
    .sortByDesc('starts_at')
    .all()
}

export const getApplicationsFromDay = async (date: any) => {
  const applications = await getUserApplications()

  return collect(applications)
    .filter((application: any) => {
      const applicationDate = moment(application.starts_at.toDate()).format('YYYY-MM-DD')
      return applicationDate === date
    })
    .take(3)
    .all()
}

const getApplicationDataFromOrganization = async (
  organization_ref: DocumentReference,
  application_id: string
) => (await getDoc(doc(organization_ref, 'applications', application_id))).data()

const createApplicationInsideUser = async (
  user_ref: DocumentReference,
  user_id: string,
  applicationData: any,
  organization_ref: DocumentReference
) => {
  const id = uuidv4()

  const applicationExists = await getDocs(
    query(
      collection(user_ref, 'applications'),
      where('application_id', '==', applicationData?.id || ''),
      where('organization_id', '==', applicationData?.organization_id || ''),
      limit(1)
    )
  )

  if (applicationExists?.docs?.length) {
    return applicationExists?.docs?.[0]?.id
  }

  await setDoc(doc(user_ref, 'applications', id), {
    access_limit: applicationData.access_limit,
    application_id: applicationData.id,
    application_ref: doc(
      db,
      'organizations',
      organization_ref.id,
      'applications',
      applicationData.id
    ),
    created_at: serverTimestamp(),
    form_id: applicationData.form_id,
    form_ref: applicationData.form_ref,
    organization_id: applicationData.organization_id,
    organization_ref,
    starts_at: applicationData.starts_at,
    status: ApplicationStatus.NOT_STARTED.toString(),
    duration: applicationData.duration,
    id,
    user_id,
    user_ref,
    name: applicationData.name
  })

  return id
}

export const saveFormDataIntoUserApplication = async (application: Application) => {
  const user = await getUserData()

  const organizationRef = doc(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string
  )

  const applicationData = await getApplicationDataFromOrganization(
    organizationRef,
    application?.application_id?.length ? application?.application_id : application.id
  )

  const applicationId = await createApplicationInsideUser(
    user.ref,
    user.id,
    applicationData,
    organizationRef
  )

  const formData = await getFormData(application.form_ref)
  const subFormData = await getSubFormsFromForm(organizationRef, application.form_ref)
  const userFormRef = doc(user.ref, 'applications', applicationId, 'forms', formData?.id)

  await setDoc(userFormRef, formData)

  for (const subForm of subFormData) {
    const subFormRef = doc(userFormRef, 'sub_forms', subForm.id)
    const organizationSubFormRef = doc(
      formData?.organization_ref,
      'forms',
      formData?.id,
      'sub_forms',
      subForm.id
    )

    let questions = await getQuestionsFromSubForm(organizationSubFormRef)

    await setDoc(subFormRef, subForm)

    if (application.is_questions_random_order) {
      const questionsOrders = collect(questions).pluck('order').shuffle().all()

      questions = collect(questions)
        .map((question, index) => ({ ...question, order: questionsOrders[index] }))
        .all()
    }

    if (application.is_answers_random_order) {
      questions = questions.map((question) => {
        if (question.alternatives?.length) {
          if (
            [QuestionType.MULTIPLE_CHOICE.toString(), QuestionType.OBJECTIVE.toString()].includes(
              question.type
            )
          ) {
            question.alternatives = collect(question.alternatives).shuffle().all()
          } else if (question.type === QuestionType.TRUE_FALSE.toString()) {
            question.alternatives = collect(question.alternatives).shuffle().all()
          }
        }

        return question
      })
    }

    for (let question of questions) {
      const questionRef = doc(subFormRef, 'questions', question.id)

      if (question.alternatives?.length) {
        const handler = {
          [QuestionType.MULTIPLE_CHOICE.toString()]: question.alternatives?.map(
            (alternative: any) => removeIsCorrectField(alternative)
          ),
          [QuestionType.OBJECTIVE.toString()]: question.alternatives?.map((alternative: any) =>
            removeIsCorrectField(alternative)
          ),
          [QuestionType.TRUE_FALSE.toString()]: question.alternatives?.map((alternative: any) =>
            removeIsTrueField(alternative)
          ),
          [QuestionType.SORTING.toString()]: question.alternatives?.map((alternative: any) =>
            removeOrderField(alternative)
          )
        }

        question.alternatives = handler?.[question?.type]
      }

      if (question.type === QuestionType.FILL_IN_THE_BLANK.toString()) {
        question = removeCorrectWordingsField(question)
      }

      if (question.type === QuestionType.CONNECT_THE_DOTS.toString()) {
        question = removeCorrectIndexesField(question)
      }

      await setDoc(questionRef, question)
    }
  }

  return applicationId
}

const getFormData = async (formRef: DocumentReference) => {
  const form = await getDoc(formRef)
  return form.data()
}

const getSubFormsFromForm = async (
  organizationRef: DocumentReference,
  formRef: DocumentReference
) => {
  const q = query(
    collection(formRef, 'sub_forms'),
    where('organization_id', '==', organizationRef.id),
    where('form_id', '==', formRef.id)
  )

  const subForms = await getDocs(q)

  return subForms.docs.map((doc) => doc.data())
}

const getQuestionsFromSubForm = async (subFormRef: DocumentReference) => {
  const q = query(collection(subFormRef, 'questions'), orderBy('order', 'desc'))
  const questions = await getDocs(q)
  return questions.docs.map((doc) => doc.data())
}

export const startUserApplication = async (userApplicationId: string) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', userApplicationId)
  await setDoc(
    applicationRef,
    {
      status: ApplicationStatus.IN_PROGRESS,
      started_at: Timestamp.fromDate(getUTCDateMoment(moment()).toDate())
    },
    { merge: true }
  )
}

const removeIsCorrectField = (question: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { is_correct, ...rest } = question
  return rest
}

const removeIsTrueField = (question: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { is_true, ...rest } = question
  return rest
}

const removeOrderField = (question: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { order, ...rest } = question
  return rest
}

const removeCorrectWordingsField = (question: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { correct_wordings, ...rest } = question
  return rest
}

const removeCorrectIndexesField = (question: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { correct_index, ...rest } = question
  return rest
}

export const isApplicationOnAnonymousUser = async (
  applicationId: string,
  anonymousUserId: string
) => {
  const userRef = doc(db, 'users', anonymousUserId)
  const userApplication = (
    await getDocs(
      query(collection(userRef, 'applications'), where('application_id', '==', applicationId))
    )
  ).docs?.[0]
  return userApplication?.id ?? null
}

export const startApplicationForAnonymousUser = async (
  applicationId: string,
  anonymousUserId: string
) => {
  const userRef = doc(db, 'users', anonymousUserId)
  const user = await getDoc(userRef)

  if (!user.exists) {
    await setDoc(
      doc(db, 'users', anonymousUserId),
      {
        id: anonymousUserId,
        name: 'Usuário anônimo',
        email: `${anonymousUserId}@anonimo.pruvo.app`
      },
      {
        merge: true
      }
    )
  }

  const form = (
    await getDocs(
      query(collectionGroup(db, 'forms'), where('application_id', '==', applicationId), limit(1))
    )
  ).docs?.[0]
  const organizationRef = doc(db, 'organizations', form.get('organization_id'))
  const formRef = doc(organizationRef, 'forms', form.id)

  const applicationData = await getApplicationDataFromOrganization(organizationRef, applicationId)
  const userApplicationId = await createApplicationInsideUser(
    userRef,
    anonymousUserId,
    applicationData,
    organizationRef
  )

  const formData = await getFormData(formRef)
  const subFormData = await getSubFormsFromForm(organizationRef, formRef)

  const userFormRef = doc(userRef, 'applications', userApplicationId, 'forms', formRef.id)
  await setDoc(userFormRef, formData)

  for (const subForm of subFormData) {
    const subFormRef = doc(userFormRef, 'sub_forms', subForm.id)
    const organizationSubFormRef = doc(
      organizationRef,
      'forms',
      formData?.id,
      'sub_forms',
      subForm.id
    )
    await setDoc(subFormRef, subForm)

    const questions = await getQuestionsFromSubForm(organizationSubFormRef)
    for (const question of questions) {
      await setDoc(doc(subFormRef, 'questions', question.id), question)
    }
  }

  return userApplicationId
}

export const getApplicationQuestionsFromAnonymous = async (
  userApplicationId: string,
  userId: string
) => {
  const userRef = doc(db, 'users', userId)
  const userApplication = await getDoc(doc(userRef, 'applications', userApplicationId))

  const form = (await getDoc(userApplication.data()?.form_ref)).data() as any
  const formRef = doc(userRef, 'applications', userApplicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))
  const questions = await getQuestions(subForms, formRef)
  const application = (
    await getDoc(
      doc(
        userApplication.get('organization_ref'),
        'applications',
        userApplication.get('application_id')
      )
    )
  ).data() as Application

  return {
    userApplication: userApplication.data(),
    questions,
    application: application
  }
}

export const getQuestionsFromAnonymous = async (userApplicationId: string, userId: string) => {
  const userRef = doc(db, 'users', userId)
  const userApplication = await getDoc(doc(userRef, 'applications', userApplicationId))

  const form = (await getDoc(userApplication.data()?.form_ref)).data() as any
  const formRef = doc(userRef, 'applications', userApplicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))
  const questions = await getQuestions(subForms, formRef)

  return collect(questions).pluck('questions').collapse().sortBy('order').all()
}

export const getApplicationQuestions = async (applicationId: string, isFeedback = false) => {
  const user = await getUserData()

  const userApplication = await getDoc(doc(user.ref, 'applications', applicationId))

  if (userApplication.data()?.status === ApplicationStatus.ENDED && !isFeedback) {
    return false
  }

  if (isFeedback && userApplication.data()?.status !== ApplicationStatus.PUBLISHED) {
    return false
  }

  const form = (await getDoc(userApplication.data()?.form_ref)).data() as any
  const formRef = doc(user.ref, 'applications', applicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))

  const questions = await getQuestions(subForms, formRef)

  const application = (await getDoc(userApplication.data()?.application_ref)).data() as Application
  application.author = (await getDoc(application.author_ref)).data()

  return {
    userApplication: userApplication.data(),
    questions,
    application
  }
}

const getQuestions = async (subForms: QuerySnapshot, formRef: DocumentReference) => {
  return await Promise.all(
    subForms.docs.map(async (subForm) => {
      const subFormRef = doc(formRef, 'sub_forms', subForm.id)
      const questionsDoc = await getDocs(collection(subFormRef, 'questions'))
      return {
        subFormId: subForm.data().id,
        subFormOrder: subForm.data().order,
        questions: questionsDoc.docs
          .filter((question) => !question.data().status || question.data().status !== 'CANCELLED')
          .map((question) => question.data())
      }
    })
  )
}

const getQuestionsDoc = async (subFormRef: DocumentReference) => {
  return await getDocs(collection(subFormRef, 'questions'))
}

export const calculateFormQuestionsGrade = async (form_ref: DocumentReference) => {
  const subForms = await getDocs(collection(form_ref, 'sub_forms'))

  const questions = await Promise.all(
    subForms.docs.map(async (subForm) =>
      (
        await getQuestionsDoc(doc(form_ref, 'sub_forms', subForm.id))
      ).docs.map((doc) => Number(doc.data().grade))
    )
  )

  return questions.reduce((acc, curr) => acc + curr.reduce((acc, curr) => acc + curr, 0), 0)
}

export const calculateFormActiveQuestionsGrade = async (form_ref: DocumentReference) => {
  const subForms = await getDocs(collection(form_ref, 'sub_forms'))

  const questions = await Promise.all(
    subForms.docs.map(async (subForm) => {
      const subFormQuestions = await getDocs(
        collection(doc(form_ref, 'sub_forms', subForm.id), 'questions')
      )

      return subFormQuestions.docs
        .map((doc) => doc.data())
        .filter((question) => question.status !== 'CANCELLED')
        .map((question) => Number(question.grade))
    })
  )

  return questions.reduce((acc, curr) => acc + curr.reduce((acc, curr) => acc + curr, 0), 0)
}

export const getActiveQuestions = async (form_ref: any) => {
  const subForms = await getDocs(collection(form_ref, 'sub_forms'))

  const questions = await Promise.all(
    subForms.docs.map(async (subForm) =>
      (await getQuestionsDoc(doc(form_ref, 'sub_forms', subForm.id))).docs
        .map((doc) => doc.data())
        .filter((question) => question.status !== 'CANCELLED')
    )
  )

  return questions.reduce((acc, curr) => acc.concat(curr), [])
}

export const endApplicationAndSaveFromAnonymous = async (
  userApplicationId: string,
  time_spent?: number,
  userId?: string
) => {
  const userRef = doc(db, 'users', userId as string)
  const applicationRef = doc(userRef, 'applications', userApplicationId)
  await setDoc(
    applicationRef,
    {
      status: ApplicationStatus.PUBLISHED,
      ended_at: Timestamp.fromDate(getUTCDateMoment(moment()).toDate()),
      ...(time_spent && { time_spent })
    },
    { merge: true }
  )
}

export const endApplicationAndSave = async (
  userApplicationId: string,
  applicationId: string,
  time_spent?: number
) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', userApplicationId)
  await calculatePartialGradeFromQuestions(userApplicationId, applicationId)
  await setDoc(
    applicationRef,
    {
      status: ApplicationStatus.ENDED,
      ended_at: Timestamp.fromDate(getUTCDateMoment(moment()).toDate()),
      ...(time_spent && { time_spent })
    },
    { merge: true }
  )
  await returnGradeToUserIfPossible(applicationId, userApplicationId)
}

const returnGradeToUserIfPossible = async (applicationId: string, userApplicationId: string) => {
  if (await isAutomaticSendGrade(applicationId)) {
    await updateAndReturnFinalGrade(userApplicationId)
  }
}

const isAutomaticSendGrade = async (applicationId: string) => {
  try {
    const application: any = await getOrganizationApplication(applicationId)
    return (application?.is_send_grade_automatic as boolean) ?? false
  } catch (e) {
    return false
  }
}

const getOrganizationApplication = async (applicationId: string) => {
  const organizationId = localStorage.getItem('activeOrganization')
  if (!organizationId) throw new Error('Organization not found')
  const applicationRef = doc(db, 'organizations', organizationId, 'applications', applicationId)
  const application = await getDoc(applicationRef)
  if (!application.exists()) throw new Error('Application not found')
  return application.data()
}

const updateAndReturnFinalGrade = async (userApplicationId: string) => {
  await updateQuestionsPartialGradeToFinalGrade(userApplicationId)
  await updateApplicationFinalGrade(userApplicationId)
  await updateStatusToPublished(userApplicationId)
}

const updateQuestionsPartialGradeToFinalGrade = async (userApplicationId: string) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', userApplicationId)
  const application = (await getDoc(applicationRef)).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(user.ref, 'applications', userApplicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))

  const questions: any = await getQuestions(subForms, formRef)
  const promisses: Array<Promise<any>> = []

  collect(questions)
    .pluck('questions')
    .collapse()
    .each((question: any) => {
      const questionRef = doc(
        applicationRef,
        'forms',
        form.id,
        'sub_forms',
        question.sub_form_id,
        'questions',
        question.id
      )

      promisses.push(
        setDoc(questionRef, { final_grade: question.partial_grade ?? 0 }, { merge: true })
      )
    })

  await Promise.all(promisses)
}

export const checkUserApplicationAccessLimit = async (applicationId: string) => {
  const user = await getUserData()
  const userApplicationRef = doc(user.ref, 'applications', applicationId)
  const userApplicationData = (await getDoc(userApplicationRef)).data() as Application
  if (
    userApplicationData?.access_limit !== 0 &&
    userApplicationData?.accessed_times >= userApplicationData?.access_limit
  ) {
    await setDoc(
      userApplicationRef,
      {
        status: ApplicationStatus.ENDED,
        ended_at: Timestamp.fromDate(getUTCDateMoment(moment()).toDate())
      },
      { merge: true }
    )
    return false
  }
  await setDoc(
    userApplicationRef,
    {
      accessed_times: (userApplicationData.accessed_times ?? 0) + 1
    },
    { merge: true }
  )
  return true
}

const updateStatusToPublished = async (userApplicationId: string) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', userApplicationId)
  await setDoc(
    applicationRef,
    {
      status: ApplicationStatus.PUBLISHED
    },
    { merge: true }
  )
}

const updateApplicationFinalGrade = async (userApplicationId: string) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', userApplicationId)
  const application = (await getDoc(applicationRef)).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(user.ref, 'applications', userApplicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))
  const questions = collect(await getQuestions(subForms, formRef))
    .pluck('questions')
    .collapse()
    .all()

  const final_grade = collect(questions).sum((question: any) => {
    const { final_grade, partial_grade } = question

    if (final_grade) {
      return final_grade
    }

    if (partial_grade) {
      return partial_grade
    }

    return 0
  })

  await setDoc(
    applicationRef,
    {
      correction: {
        final_grade
      }
    },
    { merge: true }
  )
}

export const getQuestionsCountServiceFromAnonymous = async (
  applicationId: string,
  userId: string
) => {
  const userRef = doc(db, 'users', userId)
  const applicationRef = doc(userRef, 'applications', applicationId)
  const application = (await getDoc(applicationRef)).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(userRef, 'applications', applicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))

  const questions = await getQuestions(subForms, formRef)

  return questions.reduce((acc, curr) => acc + curr.questions.length, 0)
}

export const getQuestionsAnsweredCountServiceFromAnonymous = async (
  applicationId: string,
  userId: string
) => {
  const userRef = doc(db, 'users', userId)
  const applicationRef = doc(userRef, 'applications', applicationId)
  const application = (await getDoc(applicationRef)).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(userRef, 'applications', applicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))

  const questions = await getQuestions(subForms, formRef)

  return collect(questions)
    .pluck('questions')
    .map((questions: any) => {
      return questions.filter((question: any) => {
        if (!question.answers) return false
        return question.answers.length > 0
      }).length
    })
    .sum()
}

export const getQuestionsCountService = async (applicationId: string) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', applicationId)
  const application = (await getDoc(applicationRef)).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(user.ref, 'applications', applicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))

  const questions = await getQuestions(subForms, formRef)

  return questions.reduce((acc, curr) => acc + curr.questions.length, 0)
}

export const getQuestionsAnsweredCountService = async (applicationId: string) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', applicationId)
  const application = (await getDoc(applicationRef)).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(user.ref, 'applications', applicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))

  const questions = await getQuestions(subForms, formRef)

  return collect(questions)
    .pluck('questions')
    .map((questions: any) => {
      return questions.filter((question: any) => {
        if (!question.answers) return false
        return question.answers.length > 0
      }).length
    })
    .sum()
}

export const getPercentageServiceFromAnonymous = async (applicationId: string, userId: string) => {
  const questionsCount = await getQuestionsCountServiceFromAnonymous(applicationId, userId)
  const questionsAnsweredCount = await getQuestionsAnsweredCountServiceFromAnonymous(
    applicationId,
    userId
  )

  return Number((Number(questionsAnsweredCount) / questionsCount) * 100).toFixed(2)
}

export const getPercentageService = async (applicationId: string) => {
  const questionsCount = await getQuestionsCountService(applicationId)
  const questionsAnsweredCount = await getQuestionsAnsweredCountService(applicationId)

  return Number((Number(questionsAnsweredCount) / questionsCount) * 100).toFixed(2)
}

export const updateTimeSpentOnQuestion = async (
  applicationId: string,
  questionId: string,
  timeSpent: number
) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', applicationId)
  const application = (await getDoc(applicationRef)).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(user.ref, 'applications', applicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))

  const questions = await getQuestions(subForms, formRef)

  const question = collect(questions)
    .pluck('questions')
    .flatten(1)
    .where('id', questionId)
    .first() as any

  if (question?.sub_form_ref !== undefined) {
    const subForm = await getDoc(question.sub_form_ref)

    const questionRef = doc(
      user.ref,
      'applications',
      applicationId,
      'forms',
      form.id,
      'sub_forms',
      subForm.id,
      'questions',
      question.id
    )

    await setDoc(questionRef, { time_spent: increment(timeSpent) }, { merge: true })
  }
}

export const finishApplicationWhenTimeIsOver = async (applicationId: string) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', applicationId)

  await setDoc(
    applicationRef,
    {
      status: ApplicationStatus.ENDED,
      ended_at: Timestamp.fromDate(getUTCDateMoment(moment()).toDate())
    },
    { merge: true }
  )
}

export const getApplicationStatusName = (status: ApplicationStatus) => {
  switch (status) {
    case ApplicationStatus.ENDED:
      return 'Concluída'
    case ApplicationStatus.EXPIRED:
      return 'Expirada'
    case ApplicationStatus.IN_PROGRESS:
      return 'Em progresso'
    case ApplicationStatus.CANCELLED:
      return 'Cancelada'
    case ApplicationStatus.PUBLISHED:
      return 'Nota publicada'
    case ApplicationStatus.NOT_STARTED:
      return 'Não iniciada'
    case ApplicationStatus.CORRECTED:
      return 'Corrigida'
    case ApplicationStatus.WAITING:
      return 'Aguardando correção'
    default:
      return ''
  }
}

export const getTimeSpentFromQuestionsInMinutes = async (applicationId: string) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', applicationId)
  const application = (await getDoc(applicationRef)).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(user.ref, 'applications', applicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))

  const questions = await getQuestions(subForms, formRef)

  return collect(questions)
    .pluck('questions')
    .flatten(1)
    .filter((question: any) => question.time_spent)
    .pluck('time_spent')
    .sum()
}

export const getQuestionsOnReview = async (applicationId: string) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', applicationId)
  const application = (await getDoc(applicationRef)).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(user.ref, 'applications', applicationId, 'forms', form.id)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))
  const questions = await getQuestions(subForms, formRef)
  return collect(questions).pluck('questions').flatten(1).all()
}

export const saveTimeSpent = async (applicationId: string, time_spent: number) => {
  const user = await getUserData()
  const applicationRef = doc(user.ref, 'applications', applicationId)

  setDoc(
    applicationRef,
    {
      time_spent
    },
    { merge: true }
  )
}

const calculatePartialGradeFromQuestions = async (
  userApplicationId: string,
  applicationId: string
) => {
  const user = await getUserData()
  const application = (
    await getDoc(
      doc(
        db,
        'organizations',
        localStorage.getItem('activeOrganization') as string,
        'applications',
        applicationId
      )
    )
  ).data() as Application
  const form = (await getDoc(application.form_ref)).data() as any
  const formRef = doc(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string,
    'forms',
    form.id
  )
  const subForms = await getDocs(collection(formRef, 'sub_forms'))
  const questions = collect(await getQuestions(subForms, formRef))
    .pluck('questions')
    .collapse()
    .all()

  const userApplicationRef = doc(user.ref, 'applications', userApplicationId)
  const userApplication = (await getDoc(userApplicationRef)).data() as Application
  const userApplicationForm = (await getDoc(userApplication.form_ref)).data() as any
  const userApplicationFormRef = doc(
    user.ref,
    'applications',
    userApplicationId,
    'forms',
    userApplicationForm.id
  )
  const userApplicationSubForms = await getDocs(collection(userApplicationFormRef, 'sub_forms'))
  const userApplicationQuestions = collect(
    await getQuestions(userApplicationSubForms, userApplicationFormRef)
  )
    .pluck('questions')
    .collapse()
    .all() as any

  for (const userQuestion of userApplicationQuestions) {
    const ref = doc(
      user.ref,
      'applications',
      userApplicationId,
      'forms',
      userApplicationForm.id,
      'sub_forms',
      userQuestion.sub_form_id,
      'questions',
      userQuestion.id
    )

    const question = collect(questions).where('id', userQuestion.id).first() as any

    if (!userQuestion.answers) {
      await updateDoc(ref, {
        partial_grade: 0,
        ...(question?.comment && {
          feedback: question?.comment || null
        })
      })
      continue
    }

    if (userQuestion.type === QuestionType.OBJECTIVE.toString()) {
      const selectedAnswer = collect(question.alternatives)
        .where('id', userQuestion.answers[0])
        .first() as any

      if (selectedAnswer.is_correct) {
        await updateDoc(ref, { partial_grade: Number(Number(question.grade).toFixed(2)) })
      } else {
        await updateDoc(ref, {
          partial_grade: 0,
          ...(question?.comment && {
            feedback: question?.comment || null
          })
        })
      }
    } else if (userQuestion.type === QuestionType.MULTIPLE_CHOICE.toString()) {
      const correctAnswersQuantity = collect(question.alternatives)
        .where('is_correct', true)
        .count()

      const selectedAnswers = collect(question.alternatives)
        .whereIn('id', userQuestion.answers)
        .where('is_correct', true)
        .count()

      const selectedWrongAnswers = collect(question.alternatives)
        .whereIn('id', userQuestion.answers)
        .where('is_correct', false)
        .count()

      if (selectedWrongAnswers > 0) {
        await updateDoc(ref, {
          partial_grade: 0,
          ...(question?.comment && {
            feedback: question?.comment || null
          })
        })
        continue
      }

      if (correctAnswersQuantity === selectedAnswers) {
        await updateDoc(ref, { partial_grade: Number(Number(question.grade).toFixed(2)) })
      } else if (selectedAnswers > 0 && selectedAnswers < correctAnswersQuantity) {
        const proportionalGrade = (question.grade / correctAnswersQuantity) * selectedAnswers
        await updateDoc(ref, { partial_grade: Number(proportionalGrade.toFixed(2)) })
      } else {
        await updateDoc(ref, {
          partial_grade: 0,
          ...(question?.comment && {
            feedback: question?.comment || null
          })
        })
      }
    } else if (userQuestion.type === QuestionType.TRUE_FALSE.toString()) {
      const alternativesQuantity = collect(question.alternatives).count()

      const correctAnswers = collect(question.alternatives)
        .map((alternative: any) => {
          const userQuestionAnswer = collect(userQuestion.answers)
            .where('alternative_id', alternative.id)
            .first() as any

          return userQuestionAnswer?.answer === alternative?.is_true
        })
        .filter((answer: any) => answer === true)
        .count()

      if (correctAnswers === alternativesQuantity) {
        await updateDoc(ref, { partial_grade: Number(Number(question.grade).toFixed(2)) })
      } else if (correctAnswers > 0 && correctAnswers < alternativesQuantity) {
        const proportionalGrade = (question.grade / alternativesQuantity) * correctAnswers
        await updateDoc(ref, { partial_grade: Number(proportionalGrade.toFixed(2)) })
      } else {
        await updateDoc(ref, {
          partial_grade: 0,
          ...(question?.comment && {
            feedback: question?.comment || null
          })
        })
      }
    } else if (userQuestion.type === QuestionType.MATRIX.toString()) {
      const alternativesQuantity = collect(question?.rows).count()

      const correctAnswers = collect(question?.rows)
        .map((alternative: any) => {
          const userQuestionAnswer = collect(userQuestion?.answers)
            .where('alternative_id', alternative?.id)
            .first() as any

          const column = collect(question?.columns)
            .where('id', userQuestionAnswer?.answer)
            .first() as any

          return (
            alternative?.correct_index === column?.index &&
            userQuestionAnswer?.answer === column?.id
          )
        })
        .filter((answer: any) => answer === true)
        .count()

      if (correctAnswers === alternativesQuantity) {
        await updateDoc(ref, { partial_grade: Number(Number(question.grade).toFixed(2)) })
      } else if (correctAnswers > 0 && correctAnswers < alternativesQuantity) {
        const proportionalGrade = (question.grade / alternativesQuantity) * correctAnswers
        await updateDoc(ref, { partial_grade: Number(proportionalGrade.toFixed(2)) })
      } else {
        await updateDoc(ref, {
          partial_grade: 0,
          ...(question?.comment && {
            feedback: question?.comment || null
          })
        })
      }
    } else if (userQuestion.type === QuestionType.FILL_IN_THE_BLANK.toString()) {
      const correctWordingsQuantity = collect(question.correct_wordings).count()

      const correctAnswers = collect(question.correct_wordings)
        .map((correctWording: any) => {
          const answer = collect(userQuestion.answers)
            .where('index', correctWording.index)
            .first() as any

          return answer.answer === correctWording.word
        })
        .filter((answer: any) => answer === true)
        .count()

      if (correctAnswers === correctWordingsQuantity) {
        await updateDoc(ref, { partial_grade: Number(Number(question.grade).toFixed(2)) })
      } else if (correctAnswers > 0 && correctAnswers < correctWordingsQuantity) {
        const proportionalGrade = (question.grade / correctWordingsQuantity) * correctAnswers
        await updateDoc(ref, { partial_grade: Number(proportionalGrade.toFixed(2)) })
      } else {
        await updateDoc(ref, {
          partial_grade: 0,
          ...(question?.comment && {
            feedback: question?.comment || null
          })
        })
      }
    } else if (userQuestion.type === QuestionType.SORTING.toString()) {
      const alternativesQuantity = collect(question.alternatives).count()

      const correctAnswers = collect(question.alternatives)
        .map((alternative: any) => {
          const userQuestionAnswer = collect(userQuestion.answers)

          const userAnswer = userQuestionAnswer
            .where('alternative_id', alternative.id)
            .first() as any

          return userAnswer.answer === alternative.order
        })
        .filter((answer: any) => answer === true)
        .count()

      if (correctAnswers === alternativesQuantity) {
        await updateDoc(ref, { partial_grade: Number(Number(question.grade).toFixed(2)) })
      } else if (correctAnswers > 0 && correctAnswers < alternativesQuantity) {
        const proportionalGrade = (question.grade / alternativesQuantity) * correctAnswers
        await updateDoc(ref, { partial_grade: Number(proportionalGrade.toFixed(2)) })
      } else {
        await updateDoc(ref, {
          partial_grade: 0,
          ...(question?.comment && {
            feedback: question?.comment || null
          })
        })
      }
    } else if (userQuestion.type === QuestionType.CONNECT_THE_DOTS.toString()) {
      const alternativesQuantity = collect(
        question?.alternativeDots || question?.alternativesDots || []
      ).count()

      const correctAnswers = collect(question?.alternativeDots || question?.alternativesDots || [])
        .map((alternative: any) => {
          const userQuestionAnswer = collect(userQuestion.answers)

          const userAnswer = userQuestionAnswer
            .where('alternative_id', alternative.id)
            .first() as any

          return userAnswer.index === alternative.correct_index
        })
        .filter((answer: any) => answer === true)
        .count()

      if (correctAnswers === alternativesQuantity) {
        await updateDoc(ref, { partial_grade: Number(Number(Number(question.grade).toFixed(2))) })
      } else if (correctAnswers > 0 && correctAnswers < alternativesQuantity) {
        const proportionalGrade = (question.grade / alternativesQuantity) * correctAnswers
        await updateDoc(ref, { partial_grade: Number(proportionalGrade.toFixed(2)) })
      } else {
        await updateDoc(ref, {
          partial_grade: 0,
          ...(question?.comment && {
            feedback: question?.comment || null
          })
        })
      }
    } else if (userQuestion.type === QuestionType.ESSAY.toString()) {
      const essayPreCorrection = await correctEssay(question?.grade, userQuestion?.answers?.[0])
      const ortographyPreCorrection = await correctOrtography(
        essayPreCorrection?.feedback || '',
        userQuestion?.answers?.[0]
      )
      await updateDoc(ref, {
        partial_grade: Number(essayPreCorrection?.pontuation || 0),
        feedback: essayPreCorrection?.feedback || null,
        ortographic_grade: {
          ortography: parseInt(ortographyPreCorrection?.ortography || '0'),
          vocabulary: parseInt(ortographyPreCorrection?.vocabulary || '0'),
          cohesiveResources: parseInt(ortographyPreCorrection?.cohesiveResources || '0'),
          repertoire: parseInt(ortographyPreCorrection?.repertoire || '0')
        }
      })
    }
  }
}

async function correctOrtography(feedback: string, essay: string) {
  const genModelOrtographicPoints = getGenerativeModel(vertexAI, {
    model: 'gemini-1.5-flash',
    systemInstruction: `
        Você é um corretor de redação e deve retornar apenas um JSON com a seguinte estrutura:

        {
          "ortography": "NUMBER",
          "vocabulary": "NUMBER",
          "cohesiveResources": "NUMBER",
          "repertoire": "NUMBER"
        }

        Utilize os seguintes critérios para retornar os valores:
        Ortografia
        0-1: Muitos erros graves que afetam a compreensão
        2-3: Alguns erros graves ou muitos erros menores, mas sem grande impacto na compreensão
        4-5: Poucos ou nenhum erro de ortografia

        Vocabulário
        0-1: Vocabular limitado, com muitas repetições e palavras simples
        2-3: Vocabular adequado, com algumas variações e palavras de nível médio
        4-5: Vocabular variado e sofisticado, com uso apropriado e contexto claro

        Recursos Coesivos
        0-1: Falta de coesão, frases ou ideias desconectadas
        2-3: Uso adequado, mas sem sofisticação
        4-5: Uso variado e preciso de conectivos e recursos de coesão textual

        Repertório
        0-1: Pouco repertório, com referências vagas ou inadequadas
        2-3: Repertório razoável, com referências claras, mas limitadas
        4-5: Repertório vasto, com referências culturais, históricas ou acadêmicas relevantes e bem integradas
      `
  })
  const { response: ortographicResponse } = await genModelOrtographicPoints.generateContent([
    `
        Avalie a ortografia, vocabulário, recursos coesivos e repertório da redação.
        Leve em consideração o feedback retornado pelo modelo de correção de redação,
        sendo: ${feedback}.
      `,
    {
      text: essay
    }
  ])
  return JSON.parse(ortographicResponse.text().match(/(\{.*\}|\[.*\])/s)?.[0] || '{}')
}

async function correctEssay(questionGrade: number, essayTranscribed: string) {
  const genCorrectEssayModel = getGenerativeModel(vertexAI, {
    model: 'gemini-1.5-flash',
    systemInstruction: ApplicationPrompt.correctEssayPrompt(questionGrade || 0)
  })
  const { response: correctionEssayResult } = await genCorrectEssayModel.generateContent([
    'Corrija a redação.',
    essayTranscribed
  ])
  return JSON.parse(correctionEssayResult.text().match(/(\{.*\}|\[.*\])/s)?.[0] || '{}')
}

export const getFormQuestionsFromOrganization = async (formId: string) => {
  const organizationRef = doc(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string
  )
  const formRef = doc(organizationRef, 'forms', formId)
  const subForms = await getDocs(collection(formRef, 'sub_forms'))

  const questions = await getQuestions(subForms, formRef)

  return collect(questions).pluck('questions').flatten(1).all()
}

export const updateUserApplication = async (
  applicationId: string,
  hasOpenEndedQuestion: boolean
) => {
  const user = await getUserData()
  const userApplicationRef = doc(user.ref, 'applications', applicationId)
  const userApplication = (await getDoc(userApplicationRef)).data() as Application
  const updatedUserApplication = {
    ...userApplication,
    has_open_ended_questions: hasOpenEndedQuestion
  }
  await updateDoc(userApplicationRef, updatedUserApplication)
}
