<template>
  <div>
    <template v-if="provider?.id === ProviderEnum.LT">
      <ListMaterialComponent :materials="ltData.studentsMaterials" title="Materiais de apoio" />
    </template>

    <template v-else-if="provider?.id === ProviderEnum.SABE2ED">
      <ListMaterialComponent
        :materials="sabe2EdData.studentsMaterials || []"
        title="Materiais de apoio"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import ListMaterialComponent from '@/components/support-material/ListMaterialComponent.vue'
import ProviderEnum from '@/enums/provider.enum'
import { useLoadingStore } from '@/stores/loading'
import { getProviderFromUser } from '@/services/provider.service'

const loadingStore = useLoadingStore()

const provider = ref()

onMounted(async () => {
  try {
    loadingStore.startLoading()
    provider.value = await getProviderFromUser()
  } catch (e) {
    console.error(e)
  } finally {
    loadingStore.stopLoading()
  }
})

const ltData = ref({
  studentsMaterials: [
    {
      type: 'pdf',
      title: 'SABE BRASIL – LÍNGUA PORTUGUESA – 1º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/lp_1ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – LÍNGUA PORTUGUESA – 2º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/lp_2ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – LÍNGUA PORTUGUESA – 3º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/lp_3ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – LÍNGUA PORTUGUESA – 4º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/lp_4ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – LÍNGUA PORTUGUESA – 5º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/lp_5ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – LÍNGUA PORTUGUESA – 6º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/lp_6ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – LÍNGUA PORTUGUESA – 7º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/lp_7ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – LÍNGUA PORTUGUESA – 8º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/lp_8ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – LÍNGUA PORTUGUESA – 9º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/lp_9ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – MATEMÁTICA – 1º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/mt_1ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – MATEMÁTICA – 2º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/mt_2ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – MATEMÁTICA – 3º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/mt_3ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – MATEMÁTICA – 4º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/mt_4ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – MATEMÁTICA – 5º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/mt_5ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – MATEMÁTICA – 6º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/mt_6ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – MATEMÁTICA – 7º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/mt_7ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – MATEMÁTICA – 8º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/mt_8ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – MATEMÁTICA – 9º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/mt_9ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – CIÊNCIAS HUMANAS – 9º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/ch_9ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    },
    {
      type: 'pdf',
      title: 'SABE BRASIL – CIÊNCIAS NATUREZA – 9º ANO',
      curricularUnit: 'SABE BRASIL',
      link: 'https://issuu.com/editoralt/docs/cn_9ano_aluno?fr=xKAE9_zU1NQ',
      isExternal: true
    }
  ]
})

const sabe2EdData = ref({
  studentsMaterials: [
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – LÍNGUA PORTUGUESA - 1º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_1_lp.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – LÍNGUA PORTUGUESA - 2º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_2_lp.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – LÍNGUA PORTUGUESA - 3º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_3_lp.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – LÍNGUA PORTUGUESA - 4º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_4_lp.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – LÍNGUA PORTUGUESA - 5º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_5_lp.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – LÍNGUA PORTUGUESA - 6º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_6_lp.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – LÍNGUA PORTUGUESA - 7º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_7_lp.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – LÍNGUA PORTUGUESA - 8º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_8_lp.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – LÍNGUA PORTUGUESA - 9º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_9_lp.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – MATEMÁTICA - 1º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_1_mat.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – MATEMÁTICA - 2º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_2_mat.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – MATEMÁTICA - 3º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_3_mat.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – MATEMÁTICA - 4º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_4_mat.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – MATEMÁTICA - 5º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_5_mat.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – MATEMÁTICA - 6º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_6_mat.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – MATEMÁTICA - 7º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_7_mat.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – MATEMÁTICA - 8º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_8_mat.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – MATEMÁTICA - 9º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_9_mat.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – CIÊNCIAS HUMANAS - 2º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_2_ch.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – CIÊNCIAS HUMANAS - 5º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_5_ch.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – CIÊNCIAS HUMANAS - 9º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_9_ch.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – CIÊNCIAS DA NATUREZA - 2º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_2_cn.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – CIÊNCIAS DA NATUREZA - 5º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_5_cn.pdf'
    },
    {
      img: '',
      type: 'pdf',
      title: 'SABE BRASIL 2ª EDIÇÃO – CIÊNCIAS DA NATUREZA - 9º ANO - LIVRO DO ALUNO',
      curricularUnit: 'SABE BRASIL',
      link: 'SABE2ED/aluno_9_cn.pdf'
    }
  ]
})
</script>
