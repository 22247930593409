<template>
  <template v-if="formEnded">
    <v-container>
      <v-row class="justify-center" no-gutters>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12">
              <v-sheet
                :class="{
                  'py-[20px] px-[24px] mr-[20px] rounded-xl shadow-sheet': !mobile,
                  'py-[20px] px-[24px] rounded-xl shadow-sheet': mobile
                }"
              >
                <div class="text-center text-lg">
                  Seu formulário foi salvo com sucesso.<br />
                  Você pode fechar essa janela.
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <template v-else>
    <v-container>
      <v-row v-show="isShowResolution" no-gutters>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12">
              <v-sheet
                :class="{
                  'py-[20px] px-[24px] mr-[20px] rounded-xl shadow-sheet': !mobile,
                  'py-[20px] px-[24px] rounded-xl shadow-sheet': mobile
                }"
              >
                <v-main>
                  <div :style="fontXl" class="mb-[16px] font-medium">
                    {{ data.application?.name }}
                  </div>
                  <div v-if="data.application?.instructions?.length">
                    <h2 :style="fontBase" class="font-bold">Instruções</h2>
                    <p :style="fontSm" v-html="data.application?.instructions"></p>
                  </div>
                </v-main>
              </v-sheet>
            </v-col>
            <template
              v-for="subFormQuestions of data.questions"
              :key="subFormQuestions.subFormOrder"
            >
              <v-col
                v-for="(question, index) of (collect(subFormQuestions.questions) as any)
            .sortBy('order')
            .all()"
                :id="question.id"
                :key="question.id"
                class="question-active"
                cols="12"
              >
                <v-sheet
                  :class="{
                    'py-[20px] px-[24px] mr-[20px] rounded-xl shadow-sheet': !mobile,
                    'py-[20px] px-[24px] rounded-xl shadow-sheet': mobile
                  }"
                >
                  <v-main>
                    <div class="flex items-center space-x-3 mb-3">
                      <div>
                        <v-chip
                          :style="fontSm"
                          class="!font-medium"
                          color="blue"
                          rounded
                          variant="elevated"
                          >{{ index + 1 }}
                        </v-chip>
                      </div>
                      <div>
                        <v-chip
                          :style="fontSm"
                          class="!font-medium"
                          color="blue"
                          rounded
                          variant="elevated"
                          >{{ getQuestionType(question.type) }}
                        </v-chip>
                      </div>
                      <div
                        v-if="question?.is_required"
                        class="flex px-2 flex-col items-start rounded bg-[#FFD6D6]"
                      >
                        <p class="text-xs font-normal text-[#FF0000]">Obrigatória</p>
                      </div>
                    </div>
                    <QuestionMathJax
                      :style="fontBase"
                      :text="question?.main_text"
                      class="mt-[12px] mb-[20px]"
                    />
                    <template v-if="question.type === QuestionType.OBJECTIVE">
                      <ObjectiveComponent
                        :alternatives="question.alternatives"
                        :answers="question.answers"
                        @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                      />
                    </template>
                    <template v-if="question.type === QuestionType.MATRIX">
                      <MatrixComponent
                        :answers="question.answers"
                        :columns="question.columns"
                        :rows="question.rows"
                        @answer="
                          answer(
                            subFormQuestions.subFormId,
                            question.id,
                            $event,
                            QuestionType.MATRIX
                          )
                        "
                      />
                    </template>
                    <template v-if="question.type === QuestionType.TRUE_FALSE">
                      <TrueFalseComponent
                        :alternatives="question.alternatives"
                        :answers="question.answers"
                        @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                      />
                    </template>
                    <template v-if="question.type === QuestionType.OPEN_ENDED">
                      <OpenEndedComponent
                        :answer="question.answers ? question.answers[0] : ''"
                        :params="question.params"
                        @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                      />
                    </template>
                    <template v-if="question.type === QuestionType.ESSAY">
                      <EssayComponent
                        :answer="question.answers ? question.answers[0] : ''"
                        :params="question.params"
                        @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                      />
                    </template>
                    <template v-if="question.type === QuestionType.MULTIPLE_CHOICE">
                      <MultipleChoiceComponent
                        :alternatives="question.alternatives"
                        :answers="question.answers"
                        @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                      />
                    </template>
                    <template v-if="question.type === QuestionType.SLIDER">
                      <SliderComponent
                        :answer="question.answers ? question.answers[0] : 0"
                        :params="question.params"
                        @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                      />
                    </template>
                    <template v-if="question.type === QuestionType.SORTING">
                      <SortingComponent
                        :alternatives="question.alternatives"
                        :answers="question.answers"
                        @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                      />
                    </template>
                    <template v-if="question.type === QuestionType.FILL_IN_THE_BLANK">
                      <FillInTheBlankComponent
                        :answers="question.answers"
                        :wordings="question.wordings"
                        @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                      />
                    </template>
                    <template v-if="question.type === QuestionType.CONNECT_THE_DOTS">
                      <ConnectTheDotsComponent
                        :alternatives="
                          question?.alternativeDots || question?.alternativesDots || []
                        "
                        :answers="question.answers"
                        :wordings="question.wordings"
                        @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                      />
                    </template>
                  </v-main>
                </v-sheet>
              </v-col>
            </template>
            <div class="pt-[20px] pb-[60px] w-full flex justify-end items-center mr-[28px]">
              <v-btn
                class="!border-2 text-none bg-white"
                color="blue"
                variant="outlined"
                @click="revision"
              >
                <span :style="fontSm">Revisar e finalizar</span>
              </v-btn>
            </div>
          </v-row>
        </v-col>
        <v-col v-if="!mobile" cols="3">
          <div id="formQuestionsSidebarParent" class="w-full">
            <div id="formQuestionsSidebar" class="bg-white rounded-xl p-5 h-[80vh] overflow-y-auto">
              <div class="flex justify-between text-xs">
                <CompletionInfo
                  :percentage="percentage"
                  :questions-answered-count="questionsAnsweredCount"
                  :questions-count="questionsCount"
                  questions-text="Perguntas"
                />
              </div>
              <div class="mt-[8px] mb-[24px]">
                <v-progress-linear
                  :model-value="percentage"
                  color="blue"
                  height="6"
                  rounded
                ></v-progress-linear>
              </div>
              <div class="overflow-y-auto">
                <v-radio-group v-model="activeQuestion">
                  <template
                    v-for="subFormQuestions of data?.questions"
                    :key="subFormQuestions?.subFormOrder"
                  >
                    <div
                      v-for="(question, index) in (collect(subFormQuestions?.questions) as any)?.sortBy('order')
                  .all()"
                      :key="question?.id"
                      :class="activeQuestion === question?.id && 'bg-[#D2E7F9]'"
                      class="flex cursor-pointer items-center mb-[12px] rounded-lg py-[8px]"
                      @click="goToQuestion(question?.id)"
                    >
                      <v-radio :value="question?.id" style="flex: none !important"></v-radio>
                      <span :style="fontBase" class="font-medium">
                        {{ index + 1 }} - {{ StringUtil.htmlToText(question?.main_text) }}...
                      </span>
                    </div>
                  </template>
                </v-radio-group>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-show="!isShowResolution" no-gutters>
        <v-col cols="12">
          <v-sheet class="py-5 px-6 rounded-xl w-full">
            <v-main
              :class="{
                'flex justify-between': !mobile,
                'justify-between': mobile
              }"
            >
              <div class="my-5 font-medium text-xl">
                {{ data.application?.name }}
              </div>
              <div class="my-5 flex items-start">
                <div>
                  <div :style="fontSm" class="mb-2">Perguntas respondidas</div>
                  <div class="text-sm">
                    <span class="text-[#1C86E3] text-xl font-bold"
                      >{{ questionsAnsweredCount }}/</span
                    >{{ questionsCount }}
                  </div>
                </div>
              </div>
            </v-main>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-sheet class="py-5 px-6 rounded-xl w-full mt-5 mb-5">
            <v-main class="mt-5">
              <div
                v-for="(question, index) of (collect(questionsRevision).sortBy('order').all() as any)"
                :key="question.id"
                :class="index > 0 && 'mt-[24px]'"
              >
                <div class="flex">
                  <div class="mr-[12px]">
                    <v-chip
                      :style="fontSm"
                      class="!font-bold !text-[#1C86E3]"
                      color="#D2E7F9"
                      rounded
                      variant="elevated"
                      >{{ index + 1 }}
                    </v-chip>
                  </div>
                  <div class="mr-[12px]">
                    <v-chip
                      :style="fontSm"
                      class="!font-bold !text-[#1C86E3]"
                      color="#D2E7F9"
                      rounded
                      variant="elevated"
                      >{{ getQuestionType(question.type) }}
                    </v-chip>
                  </div>
                  <div>
                    <v-chip
                      v-if="!question.answers"
                      :style="fontSm"
                      class="!font-bold !text-[#F5260A]"
                      color="#FDD4CE"
                      rounded
                      variant="elevated"
                      >Não respondida
                    </v-chip>
                  </div>
                </div>
                <div :style="fontBase" class="mt-[12px]">
                  <QuestionMathJax :text="question?.main_text" />
                </div>
                <div v-if="question.answers" class="pt-3">
                  <QuestionResolutionReview :question="question" />
                </div>
              </div>
              <div class="flex justify-end pt-3">
                <v-btn
                  class="!border-2 text-none bg-white mr-[20px]"
                  color="blue"
                  variant="outlined"
                  @click="isShowResolution = true"
                >
                  <span :style="fontSm">Retornar ao formulário</span>
                </v-btn>
                <v-btn class="!border-2 text-none bg-white" color="blue" @click="endApplication">
                  <span :style="fontSm">Finalizar</span>
                </v-btn>
              </div>
            </v-main>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </template>
</template>

<script lang="ts" setup>
import {
  endApplicationAndSaveFromAnonymous,
  getApplicationQuestionsFromAnonymous,
  getPercentageServiceFromAnonymous,
  getQuestionsAnsweredCountServiceFromAnonymous,
  getQuestionsCountServiceFromAnonymous,
  getQuestionsFromAnonymous,
  isApplicationOnAnonymousUser,
  startApplicationForAnonymousUser
} from '@/services/application.service'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { getQuestionType, updateAnswer } from '@/services/question.service'
import { QuestionType } from '@/enums/question-type.enum'
import MatrixComponent from '@/components/question/MatrixComponent.vue'
import collect from 'collect.js'
import { useFontZoomStore } from '@/stores/font'
import { useDisplay } from 'vuetify'
import { useLoadingStore } from '@/stores/loading'
import { useConfettiStore } from '@/stores/confetti'
import { useSnackbarStore } from '@/stores/snakebar'
import EssayComponent from '@/components/question/EssayComponent.vue'
import QuestionMathJax from '@/components/question/QuestionMathJax.vue'
import QuestionResolutionReview from '@/components/resolution/QuestionResolutionReview.vue'
import CompletionInfo from '@/components/resolution/CompletionInfo.vue'
import ConnectTheDotsComponent from '@/components/question/ConnectTheDotsComponent.vue'
import FillInTheBlankComponent from '@/components/question/FillInTheBlankComponent.vue'
import SortingComponent from '@/components/question/SortingComponent.vue'
import SliderComponent from '@/components/question/SliderComponent.vue'
import MultipleChoiceComponent from '@/components/question/MultipleChoiceComponent.vue'
import OpenEndedComponent from '@/components/question/OpenEndedComponent.vue'
import TrueFalseComponent from '@/components/question/TrueFalseComponent.vue'
import ObjectiveComponent from '@/components/question/ObjectiveComponent.vue'
import { auth } from '@/config/firebase'
import { signInAnonymously } from 'firebase/auth'
import { signOut } from '@firebase/auth'
import StringUtil from '@/utils/string.util'

const { mobile } = useDisplay()
const route = useRoute()
const loadingStore = useLoadingStore()
const confettiStore = useConfettiStore()
const snackbarStore = useSnackbarStore()

const activeQuestion = ref<string>('')
const data = ref({} as any)
const percentage = ref(null as any)
const questionsCount = ref(null as any)
const questionsAnsweredCount = ref(null as any)
const isShowResolution = ref(true)
const questionsRevision = ref([] as any)
const formEnded = ref(false)

const fontSm = computed(() => useFontZoomStore().getFontSm)
const fontBase = computed(() => useFontZoomStore().getFontBase)
const fontXl = computed(() => useFontZoomStore().getFontXl)

onMounted(async () => {
  try {
    loadingStore.startLoading()
    const anonymousUser = await signInAnonymously(auth)
    let userApplicationId = await isApplicationOnAnonymousUser(
      route.params.applicationId as string,
      anonymousUser.user.uid
    )

    if (!userApplicationId) {
      userApplicationId = await startApplicationForAnonymousUser(
        route.params.applicationId as string,
        anonymousUser.user.uid
      )
    }

    data.value = await getApplicationQuestionsFromAnonymous(
      userApplicationId,
      anonymousUser.user.uid
    )
    percentage.value = getPercentage(data.value?.questions)
    questionsCount.value = getQuestionsCount(data.value?.questions)
    questionsAnsweredCount.value = getQuestionsAnsweredCount(data.value?.questions)

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.4
    })

    await nextTick()

    data.value.questions.forEach((subFormQuestions: any) => {
      subFormQuestions.questions.forEach((question: any) => {
        const el = document.getElementById(question.id)

        if (el) {
          observer?.observe(el)
        }
      })
    })
  } catch (e) {
    console.log(e)
  } finally {
    loadingStore.stopLoading()
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', () =>
    handleScroll('formQuestionsSidebar', 'formQuestionsSidebarParent', 60)
  )
})

watch(isShowResolution, async (newValue: boolean) => {
  if (!newValue) {
    questionsRevision.value = await getQuestionsFromAnonymous(
      data.value.userApplication.id,
      auth.currentUser?.uid as string
    )
  }
})

const getPercentage = (questions: any) => {
  const questionsData = collect(questions).pluck('questions')
  const questionsAnswered = questionsData
    .map((questions: any) => {
      return questions.filter((question: any) => {
        if (!question?.answers) return false
        return question?.answers?.length > 0
      }).length
    })
    .sum()

  return Number(
    (Number(questionsAnswered) /
      Number(questionsData.map((questions: any) => questions?.length).sum())) *
      100
  ).toFixed(2)
}

const getQuestionsCount = (questions: any) => {
  return collect(questions)
    .pluck('questions')
    .map((questions: any) => questions?.length)
    .sum()
}

const getQuestionsAnsweredCount = (questions: any) => {
  return collect(questions)
    .pluck('questions')
    .map((questions: any) => {
      return questions?.filter((question: any) => {
        if (!question?.answers) return false
        return question?.answers?.length > 0
      }).length
    })
    .sum()
}

const answer = async (subFormId: string, questionId: string, answer: any, type?: QuestionType) => {
  const { user_ref, form_ref, id } = data.value.userApplication

  await updateAnswer(user_ref, form_ref, id, subFormId, questionId, answer, type)

  percentage.value = await getPercentageServiceFromAnonymous(
    data.value.userApplication.id,
    auth.currentUser?.uid as string
  )
  questionsCount.value = await getQuestionsCountServiceFromAnonymous(
    data.value.userApplication.id,
    auth.currentUser?.uid as string
  )
  questionsAnsweredCount.value = await getQuestionsAnsweredCountServiceFromAnonymous(
    data.value.userApplication.id,
    auth.currentUser?.uid as string
  )
}

const endApplication = async () => {
  try {
    loadingStore.startLoading()
    await endApplicationAndSaveFromAnonymous(
      data.value.userApplication.id,
      undefined,
      auth.currentUser?.uid as string
    )
    confettiStore.startConfetti()
    setTimeout(() => confettiStore.stopConfetti(), 3000)
    formEnded.value = true
    await signOut(auth)
  } catch (e) {
    console.log(e)
    snackbarStore.showSnackbar(
      ['Ah não! Não conseguimos terminar a avaliação. Que tal tentar mais uma vez?'],
      'error'
    )
  } finally {
    loadingStore.stopLoading()
  }
}

const handleIntersection = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      activeQuestion.value = entry.target?.id ?? ''
    }
  })
}

const revision = async () => {
  // const questions = data.value.questions?.[0]?.questions

  // if (questions?.some((q: any) => q.is_required && !q.answers?.length)) {
  //   snackbarStore.showSnackbar(
  //     ['Você precisa responder todas as perguntas obrigatórias para finalizar.'],
  //     'error'
  //   )
  //   return
  // }

  activeQuestion.value = ''
  isShowResolution.value = false
}

const goToQuestion = (questionId: string) => {
  activeQuestion.value = questionId
  const element = document.getElementById(questionId)
  if (element) {
    const offset = 160
    window.scrollTo({
      top: element.offsetTop - offset,
      behavior: 'smooth'
    })
  }
}

const handleScroll = (
  elementId: string,
  parentElementId: string,
  offset: number,
  additionalClass: string = ''
) => {
  const element = document.getElementById(elementId) as HTMLElement
  const parentElement = document.getElementById(parentElementId) as HTMLElement

  if (!element) return

  if (window.scrollY > offset) {
    element.classList.add('position-fixed')
    if (additionalClass) element.classList.add(additionalClass)
    element.style.width = `${parentElement.offsetWidth}px`
    return
  }

  element.classList.remove('position-fixed')
  if (additionalClass) element.classList.remove(additionalClass)
  element.style.width = ''
}

window.addEventListener('scroll', () =>
  handleScroll('formQuestionsSidebar', 'formQuestionsSidebarParent', 60)
)
</script>

<style scoped>
.shadow-sheet {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.02);
}
</style>
