import { QuestionType } from '@/enums/question-type.enum'
import { doc, DocumentReference, getDoc, updateDoc } from 'firebase/firestore'

export const getQuestionType = (type: string) => {
  return {
    MULTIPLE_CHOICE: 'Resposta múltipla',
    OBJECTIVE: 'Resposta única',
    MATRIX: 'Matriz',
    OPEN_ENDED: 'Discursiva',
    SORTING: 'Ordenação',
    SLIDER: 'Barra de progresso',
    CONNECT_THE_DOTS: 'Ligar pontos',
    TRUE_FALSE: 'Verdadeiro ou falso',
    FILL_IN_THE_BLANK: 'Preencher lacunas',
    ESSAY: 'Redação'
  }[type]
}

export const getQuestionDescription = (type: string) => {
  return {
    CONNECT_THE_DOTS:
      'Questão que apresenta dois conjuntos de opções e requer a ligação de uma coluna à outra',
    FILL_IN_THE_BLANK:
      'Questão que apresenta um enunciado ou frase com uma ou mais lacunas a serem preenchidas',
    MATRIX: 'Questão que apresenta uma estrutura organizada em linhas e colunas',
    MULTIPLE_CHOICE: 'Questão que possui múltiplas alternativas como respostas corretas',
    OBJECTIVE: 'Questão que possui apenas uma alternativa como resposta correta',
    OPEN_ENDED: 'Questão que exige a elaboração de um texto',
    SLIDER: 'Barra de progresso',
    SORTING:
      'Questão que apresenta um conjunto de elementos que devem ser organizados em uma sequência específica',
    TRUE_FALSE: 'Questão onde se deve indicar para cada alternativa se é verdadeira ou falsa',
    ONLY_TEXT: 'Apenas texto',
    ESSAY: 'Questão que exige a elaboração de um texto'
  }[type]
}

export const updateAnswer = async (
  user_ref: DocumentReference,
  form_ref: DocumentReference,
  applicationId: string,
  subFormId: string,
  questionId: string,
  answer: any,
  type?: QuestionType
) => {
  const user = await getDoc(user_ref)
  const form = (await getDoc(form_ref)).data()

  const userQuestionRef = doc(
    user.ref,
    'applications',
    applicationId,
    'forms',
    form?.id,
    'sub_forms',
    subFormId,
    'questions',
    questionId
  )

  updateDoc(userQuestionRef, {
    answers: type === QuestionType.MATRIX ? answer.filter((ans: any) => ans.answer) : answer
  })
}
