<template>
  <div>
    <div :style="fontXs">Progresso</div>
    <div :style="fontSm" class="font-bold text-[#1C86E3]">{{ percentage }}%</div>
  </div>
  <div>
    <div :style="fontXs">{{ questionsText ?? 'Questões' }} respondidas</div>
    <div :style="fontSm" class="text-right">
      <span class="font-bold text-[#1C86E3]">{{ questionsAnsweredCount }}/</span
      >{{ questionsCount }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useFontZoomStore } from '@/stores/font'
import { computed } from 'vue'

const fontXs = computed(() => useFontZoomStore().getFontXs)
const fontSm = computed(() => useFontZoomStore().getFontSm)

defineProps(['percentage', 'questionsCount', 'questionsAnsweredCount', 'questionsText'])
</script>
